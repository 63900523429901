<template>
  <div>
    <!-- Breadcrumb start -->
    <section class="bradcrumb_sec">
      <div class="container-fluid custome_container">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="bread_crumb_box mb_22">
              <h2 class="heading_2 font_bold mb_23">
                Working from the lab <span class="text_primary">guide</span>
              </h2>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }">
                      Home
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Working from the lab
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Breadcrumb end -->
    <section :class="!company_code ? 'work_from_lab how_else_ergo mb-0' : 'work_from_lab how_else_ergo'">
      <!-- <img src="../assets/images/work-from-office/first-section.png" alt="img" class="first_section"> -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 col-xxl-12">
            <div class="row">
              <div class="col-md-6 col-lg-4">
                <div class="custome_card card2">
                  <router-link :to="{ name: 'bench_work' }">
                    <img src="../assets/images/work_from_lab/lab-1.png" alt="img" class="mb_66" />
                    <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                      <img src="../assets/images/home-essentials/bench_work.png" alt="img" class="mb_66">
                    </a> -->
                    <div class="card2_text_box">
                      <h5 class="
                          font_bold
                          text-center
                          font_size_26
                          mb_20
                          text_black
                        ">
                        Bench Work
                      </h5>
                      <p class="text-center font_size_18 mb_20 text_black">
                        When done right, bench work is a breeze. Learn more.
                      </p>
                      <p class="text_balticsea font_size_14 text_black">
                        5 minute journey
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="custome_card card2">
                  <router-link :to="{ name: 'pipetting' }">
                    <img src="../assets/images/work_from_lab/lab-2.png" alt="img" class="mb_66" />
                    <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                      <img src="../assets/images/home-essentials/pipetting.png" alt="img" class="mb_66">
                    </a> -->
                    <div class="card2_text_box">
                      <h5 class="
                          font_bold
                          text-center
                          font_size_26
                          mb_20
                          text_black
                        ">
                        Pipetting
                      </h5>
                      <p class="text-center font_size_18 mb_20 text_black">
                        Learn how to side-step pipetting woes and learn how to feel great.
                      </p>
                      <p class="text_balticsea font_size_14 text_black">
                        5 minute journey
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="custome_card card2">
                  <router-link :to="{ name: 'microscope' }">
                    <img src="../assets/images/work_from_lab/lab-3.png" alt="img" class="mb_66" />
                    <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                      <img src="../assets/images/home-essentials/microscopes.png" alt="img" class="mb_66">
                    </a> -->
                    <div class="card2_text_box">
                      <h5 class="
                          font_bold
                          text-center
                          font_size_26
                          mb_20
                          text_black
                        ">
                        Microscopes
                      </h5>
                      <p class="text-center font_size_18 mb_20 text_black">
                        Your eyes, back and neck will rejoice after learning
                        these tips.
                      </p>
                      <p class="text_balticsea font_size_14 text_black">
                        10 minute journey
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="custome_card card2">
                  <router-link :to="{ name: 'material_handling' }">
                    <img src="../assets/images/work_from_lab/lab-4.png" alt="img" class="mb_66" />
                    <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal4">
                      <img src="../assets/images/home-essentials/material_handling.png" alt="img" class="mb_66">
                    </a> -->
                    <div class="card2_text_box">
                      <h5 class="
                          font_bold
                          text-center
                          font_size_26
                          mb_20
                          text_black
                        ">
                        Material Handling
                      </h5>
                      <p class="text-center font_size_18 mb_20 text_black">
                        Whether you are bending or lifting, we’ve got your back.
                      </p>
                      <p class="text_balticsea font_size_14 text_black">
                        5 minute journey
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="custome_card card2">
                  <router-link :to="{ name: 'hood_work' }">
                    <img src="../assets/images/work_from_lab/lab-5.png" alt="img" class="mb_66" />
                    <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal5">
                      <img src="../assets/images/home-essentials/hood_work.png" alt="img" class="mb_66">
                    </a> -->
                    <div class="card2_text_box">
                      <h5 class="
                          font_bold
                          text-center
                          font_size_26
                          mb_20
                          text_black
                        ">
                        Hood Work
                      </h5>
                      <p class="text-center font_size_18 mb_20 text_black">
                        No more awkward positions when doing your hood work.
                      </p>
                      <p class="text_balticsea font_size_14 text_black">
                        5 minute journey
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="custome_card card2">
                  <router-link :to="{ name: 'lab_stretches' }">
                    <img src="../assets/images/work_from_lab/lab-6.png" alt="img" class="mb_66" />
                    <div class="card2_text_box">
                      <h5 class="
                          font_bold
                          text-center
                          font_size_26
                          mb_20
                          text_black
                        ">
                        Lab Stretches
                      </h5>
                      <p class="text-center font_size_18 mb_20 text_black">
                        Combat awkward postures and work positions with these
                        stretches.
                      </p>
                      <p class="text_balticsea font_size_14 text_black">
                        5 minute journey
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="take_an_ergonomic_parttwo mb_44" v-show="company_code">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 col-xl-10">
            <div class="ergonomic-image pt_26 pb_26 ">
              <div class="overlay">
                <div class="row">
                  <div class="col-md-12 col-lg-7 col-xxl-6">
                    <div class="left-section pl_60 pr_60">
                      <h6 class="sub_heading_1 font_bold mb_22 text-white">Take an ergonomic self-assessment</h6>
                      <p class="text-white p_20 mb_54"> Take a self-assessment todayand see how ergo can help you feel
                        great, enjoy more energy and supercharge your productivity. </p>
                      <div class="mt_14">
                        <router-link :to="{ name: 'self_assessment_signup' }"
                          class="btn btn_secondary font_size_16">Take a Self-Assessment</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
  data () {
    return {
      company_code: true,      
    }
  },
    name: "Work_from_lab",
    mounted(){
        document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
        let data = this.$store.getters["auth/authUser"];
        let company_data = data.client;
        if(company_data.self_assessment_button_status == 0){
          this.company_code = false;
        }
        if (localStorage.getItem("userToken") == null || localStorage.getItem("employeeEmail") == null) 
        {
          this.$router.push({ name: "login" }).catch(() => true);
        }                 
      }      
  }
</script>